.main {
  /* position: absolute; */
  /* background-color: #CE2929
;
    width: 100vw;
    height: 100vh; */
  display: flex;
}
.sidebar {
  position: sticky;
  top: 0;
  left: 0;
  /* width: 20%; */
  height: 100vh;
  background-color: white;
  z-index: 1000;
}
.headbar {
  /* position : relative; */
  /* width: 80%; */
  /* height: 60px; */
  background-color: yellow;
}
.main-bar{
  width: 100%

}


@media screen and (max-width:960px) {
  .main-bar{
    width:100% !important;
  }
}


@media screen and (max-width:960px) {
  .main-bar-child{
    width:100% !important;
    max-width: 100% !important;
  }
}
.open{
  max-width:  calc(100vw - 20vw);
}
.close{
  max-width: calc(100vw - 100px);
}

@media screen and (max-width:960px) {
  .close{
    max-width:100%;
  }
}