::-webkit-file-upload-button {
    display: none;
}

#file-upload {
    /* display: block; */
    position: relative;
    left: 40%;
}

.image-section-inventory{
    width: 100%;
  padding: 1rem;
    display: flex;
    gap: 8px;
    align-items: start;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.image-section-inventory img{
   width: 10rem;
   border-radius: 10px;
  transition: all .2s ease;
  object-fit: cover;
}
.image-section-inventory img:hover{
    box-shadow: 0px 9px 25px -12px rgba(115,115,115,0.66);
    padding: 5px;
    cursor: pointer;
    border-radius: 10px;
    
   
 }
.imageAdd{
    width: 10rem;
    background-color: rgb(235, 235, 235);
  
    height: 10rem;
    border-radius: 20px;
    transition: all .2s ease;
    cursor: pointer;
}
.imageAdd label{
   cursor: pointer;
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
}
.imageAdd:hover{
    
    background-color: rgb(216, 216, 216);
   
}


.img-container{
    position: relative;
}
.remove-btn{
    position: absolute;
    top: 5px;
    right: 5px;

    padding: 5px;
    border-radius: 5px;
    background-color: rgb(235, 235, 235);
    z-index: 10;
    /* background: transparent; */
    outline: none;
    border: none;
}

.close{
    padding: 0 !important;
}