.admin-layout {
  display: flex;
  /* height: 130vh; */
  border-radius: 1rem;
  /* width: 70vw; */
  background-color: #E5E4E2;
  position: relative;
  overflow-x: hidden;
}

.makeStyles-divStyle-30 {
  /* width: 85vw; */
  height: 100%;
  background-color: #F5F5F5;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout {
  /* background-image: linear-gradient(to right, #F53803, #F5D020 ); */
  background-color: #FFFFFF;
  height: 100vh;
}

.headbar {
  display: "flex";
  flex-direction: "column";
  /* width: "500%"; */
  background-color: #FFFFFF;
}

/* .............................................................................. */

.adminmain{
  height: 98vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.adminSubMain{
  display: grid;
  height: 100%;
  width: 100%;
  overflow: hidden;
  grid-template-columns: 18rem auto;
}

.container{
  width:"180vw";
}