.modalWrap {
  position: absolute;
  top: "50%";
  left: "50%";
  width: 1100px;
  height: 650px;
  overflow: auto;
  font-family: "Montserrat";
  transform: "translate(-50%, -50%)";
  background-color: "#fff";
  box-shadow: 24;
  border-radius: "12px";
  overflow: hidden scroll;
  scrollbar-width: none;

}

.modalWrapComment {
  position: absolute;
  top: "50%";
  left: "50%";
  width: 1100px;
  height: 650px;
  overflow: auto;
  font-family: "Montserrat";
  transform: "translate(-50%, -50%)";
  background-color: "#fff";
  box-shadow: 24;
  border-radius: "12px";
  overflow: hidden scroll;
  scrollbar-width: none;
}

.comment-box {
  display: flex;
  flex-direction: column;
  margin: 20px;
  align-items: center;
  justify-content: space-between;
  height: 300px;
}

.comment-close-button {
  background-color: #FEDD17;
  width: 120px;
  height: 35px;
  font-weight: bold;
  font-size: large;
  border: none;
  border-radius: 7px;
  cursor: pointer;
}

.rhap_container {
  background-color: #FEDD15;
  /* Background color */
}

.rhap_progress-bar,
.rhap_progress-filled {
  background-color: black;
  /* Progress bar and filled color */
}

.rhap_time {
  color: black;
  /* Font color for time indicators */
  font-size: 12px;
}

.rhap_controls-button {
  color: black;
  /* Control button color */
}

.rhap_volume-button {
  color: black;
  /* Volume button color */
  font-size: 20px;
}

.rhap_progress-container {
  height: 10px;
}

.rhap_progress-indicator {
  background: black;
  height: 18px;
}

.rhap_main-controls-button {
  color: black;
  font-size: 20px;
}


.modalWrap::-webkit-scrollbar {
  width: 6px !important;
}

.modalWrapComment::-webkit-scrollbar {
  width: 0 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media (max-width: 1000px) {
  .modalWrap {
    width: 90%;
  }
}

@media (max-width: 1000px) {
  .modalWrapComment {
    width: 90%;
  }
}

.autobox {
  /* font-family: sans-serif; */
  text-align: center;
}