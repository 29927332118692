.modalWrapSale {
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: "Montserrat";
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 24;
  border-radius: 10px;
  height: 500px;
  overflow-x:  scroll ;
  scrollbar-width: 2px ;
}
.modalWrapSale::-webkit-scrollbar {
  width: 8px !important;

}

.modalWrapSale::-webkit-scrollbar-thumb {
  width: 8px !important;
  background-color: rgb(161, 161, 161);

}
@media (max-width: 1000px) {
  .modalWrapSale {
    width: 700px;
  }
}
@media (max-width: 600px) {
  .modalWrapSale {
    width: 90vw;
  }
}

.modalWrap {
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: "Montserrat";
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 24;
  border-radius: 10px;
  height: 500px;
  overflow-x:  scroll ;
  scrollbar-width: 2px ;
}
.modalWrap::-webkit-scrollbar {
  width: 5px !important;

}

.modalWrap::-webkit-scrollbar-thumb {
  width: 5px !important;
  background-color: rgb(161, 161, 161);

}


@media (max-width: 1000px) {
  .modalWrap {
    width: 700px;
  }
}
@media (max-width: 600px) {
  .modalWrap {
    width: 90vw;
  }
}

.salesAddress {
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: "Montserrat";
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 24;
  border-radius: 10px;
  overflow: hidden scroll;
  scrollbar-width: none;
}


.salesAddress::-webkit-scrollbar {
  width: 8px !important;

}
.salesAddress::-webkit-scrollbar-thumb {
  width: 6px !important;
  background-color: rgb(161, 161, 161);
}
@media (max-width: 1000px) {
  .salesAddress {
    width: 700px;
  }
}
@media (max-width: 600px) {
  .salesAddress {
    width: 90vw;
  }
}
/* .toastify-toaster {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 80% !important;
} */

.Toastify__toast {
  min-height: 30px; 
  padding: 8px 16px; 
  font-size: 14px; 
  display: flex;
  align-items: center; 
}

.Toastify__close-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.pac-container {
  z-index: 999999 !important;
}
